import type { RawWidgetConfigFragment } from '../../gql/fragments/__generated/RawWidgetConfig';
import type { WhlModuleType } from '../../models/WhlModuleType';

export default (
  widgetConfig: RawWidgetConfigFragment | null,
  module: WhlModuleType
): boolean => {
  const enabledTypes =
    (widgetConfig?.usageForEntityType ?? '').split(',') ?? [];

  return enabledTypes.includes(module);
};
