import type { RawWidgetConfigFragment } from '../../gql/fragments/__generated/RawWidgetConfig';
import type { AppearanceFilter } from '../../gql/schema';
import type { Nullable } from '../../models/CustomUtilityTypes';

export default (
  dataValue: Nullable<RawWidgetConfigFragment>
): AppearanceFilter | null => {
  if (dataValue?.deliveryChannel?.id) {
    return {
      deliveryChannel: dataValue.deliveryChannel.id,
    };
  }
  return null;
};
