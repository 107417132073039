import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import type { WhlModuleType } from '../models/WhlModuleType';
import rawIsWhlModuleEnabled from '../shared/utils/rawIsWhlModuleEnabled';

export default (
  widgetConfig: MaybeRef<RawWidgetConfigFragment | null>,
  module: WhlModuleType
): boolean => {
  if (rawIsWhlModuleEnabled(toValue(widgetConfig), module)) {
    return true;
  } else {
    throw createError({
      statusCode: 404,
      statusMessage: 'Page Not Found (Module disabled)',
      fatal: true,
    });
  }
};
